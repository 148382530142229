import { ImageSource } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Link from "next/link";

import { useTranslation } from "../../shared/translate/NextI18next";
import { UonCardLocation } from "../CardItemTransactionStandard/CardItemTransactionStandard";
import { sc } from "./CardItemTransactionCollectable.styled";

export interface CardItemTransactionCollectableBottomDriver {
  location: UonCardLocation;
  collectionName: string;
  collectionOwnerUrl: string;
  onCollectionNameClicked(): void;
  protectedBy: string;
  protectedByUrl: string;
  onProtectedByClicked(): void;
  donatedBy: string;
  donatedByUrl: string;
  onDonatedByClicked(): void;
  registeredTo: string;
  registeredToUrl: string;
  onRegisteredToClicked(): void;
  collectionCreatedBy: string;
  onCollectionCreatedByClicked(): void;
  id: string;
  imageSource: typeof ImageSource.UON_IMAGE;

  onGiveBtnClicked(): void;
  isGiftActive: boolean;

  hoverItem?: "VISIT" | "GIVE"; // This prop is for storybook only
  shouldOpenWithBlank?: boolean; // use on certificate
  isGiftButtonVisible: boolean;
  onVistButtonClicked(): void;
}

interface IProps {
  driver: CardItemTransactionCollectableBottomDriver;
}
export const CardItemTransactionCollectableBottom = observer(
  function CardItemTransactionCollectableBottom(props: IProps) {
    const {
      location,
      collectionName,
      collectionOwnerUrl,
      onCollectionNameClicked,
      donatedBy,
      donatedByUrl,
      onDonatedByClicked,
      protectedBy,
      protectedByUrl,
      onProtectedByClicked,
      registeredTo,
      registeredToUrl,
      onRegisteredToClicked,
      onGiveBtnClicked,
      hoverItem,
      isGiftActive,
      collectionCreatedBy,
      onCollectionCreatedByClicked,
      onVistButtonClicked,
    } = props.driver;

    const { t } = useTranslation("CardItemPromotion");

    return (
      <sc.BottomContainer data-cy="CardItemTransactionPromotionBottom-Container">
        <sc.TransactionDetail>
          <sc.ReserveName data-cy="CardItemTransactionPromotionBottom-CollectionName">
            <Link
              href={collectionOwnerUrl}
              target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
              passHref
              legacyBehavior
            >
              <sc.UonInfoLink
                onClick={(e) => {
                  e.preventDefault();
                  onCollectionNameClicked();
                }}
              >
                {collectionName}
              </sc.UonInfoLink>
            </Link>
          </sc.ReserveName>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-CollectionCreatedBy">
            {t("card-transaction-promotion.collection-created-By")}
            <Link
              href={collectionOwnerUrl}
              target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
              passHref
              legacyBehavior
            >
              <sc.UonInfoLink
                onClick={(e) => {
                  e.preventDefault();
                  onCollectionCreatedByClicked();
                }}
              >
                {collectionCreatedBy}
              </sc.UonInfoLink>
            </Link>
          </sc.BottomText>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-ProtectedBy">
            {t("card-transaction-promotion.protected-by")}
            <Link
              href={protectedByUrl}
              target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
              passHref
              legacyBehavior
            >
              <sc.UonInfoLink
                onClick={(e) => {
                  e.preventDefault();
                  onProtectedByClicked();
                }}
              >
                {protectedBy}
              </sc.UonInfoLink>
            </Link>
          </sc.BottomText>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-CreatedBy">
            {t("card-transaction-promotion.donated-by")}
            <Link
              href={donatedByUrl}
              target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
              passHref
              legacyBehavior
            >
              <sc.UonInfoLink
                onClick={(e) => {
                  e.preventDefault();
                  onDonatedByClicked();
                }}
              >
                {donatedBy}
              </sc.UonInfoLink>
            </Link>
          </sc.BottomText>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-RegisteredTo">
            {t("card-transaction-promotion.registered-to")}
            <Link
              href={registeredToUrl}
              target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
              passHref
              legacyBehavior
            >
              <sc.UonInfoLink
                onClick={(e) => {
                  e.preventDefault();
                  onRegisteredToClicked();
                }}
              >
                {registeredTo}
              </sc.UonInfoLink>
            </Link>
          </sc.BottomText>
        </sc.TransactionDetail>
        <sc.ButtonsContainer
          centered={
            location === UonCardLocation.DONATION_SUCCESS ||
            location === UonCardLocation.CERTIFICATE
          }
        >
          {!isGiftActive && location === UonCardLocation.WALLET && (
            <>
              <Link
                href="/m2/[transactionId]"
                as={`/m2/${props.driver.id}`}
                target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
                passHref
                legacyBehavior
              >
                <sc.VisitBtn
                  data-cy="CardItemTransactionPromotionBottom-VisitButton"
                  className={hoverItem === "VISIT" ? "hover" : ""}
                  target={props.driver.shouldOpenWithBlank ? "_blank" : "_self"}
                  onClick={onVistButtonClicked}
                >
                  {t("card-transaction-promotion.visit-button")}
                </sc.VisitBtn>
              </Link>
              {props.driver.isGiftButtonVisible && (
                <sc.GiveBtn
                  data-cy="CardItemTransactionPromotionBottom-GiveButton"
                  className={hoverItem === "GIVE" ? "hover" : ""}
                  onClick={onGiveBtnClicked}
                >
                  {t("card-transaction-promotion.give-button")}
                </sc.GiveBtn>
              )}
            </>
          )}
          {(location === UonCardLocation.DONATION_SUCCESS ||
            location === UonCardLocation.CERTIFICATE) && (
            <Link
              href="/m2/[transactionId]"
              as={`/m2/${props.driver.id}`}
              passHref
              legacyBehavior
            >
              <sc.VisitYourM2
                data-cy="CardItemTransactionPromotionBottom-VisitYourM2"
                onClick={props.driver.onVistButtonClicked}
              >
                {t("card-transaction-promotion.visit-your-m2")}
              </sc.VisitYourM2>
            </Link>
          )}
        </sc.ButtonsContainer>
      </sc.BottomContainer>
    );
  },
);
